import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const AboutYouPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="About You" />
    <BackgroundImage
      fluid={props.data.aboutYouImage.childImageSharp.fluid}
      alt=""
    >
      <Header headerText="About You"></Header>
    </BackgroundImage>
    <Section>
      <h2>It's all about you</h2>
      <p>
        Your personal finances, your family's finances, and if you are a
        business owner, your business's financial futures are all interwoven
        together. We will take time to really understand these intricacies, to
        ensure the financial plan matches your requirements, but is also
        flexible and adaptable.
      </p>
      <p>
        We also understand that everyone is different and that should be
        embraced. Your goals will be unique to you and therefore so should your
        financial plan. It is essential that we provide a bespoke service for
        you, your family and your business.
      </p>

      <TwmButton toRef={props.pageContext.firm + "/contact-us"}>
        Contact Us
      </TwmButton>
    </Section>
  </Layout>
)

export default AboutYouPage

export const pageQuery = graphql`
  query {
    aboutYouImage: file(relativePath: { eq: "about-you.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
